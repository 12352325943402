@media screen and (max-width: 1399.99px) {
  .aboutusSection .aboutSpace {
    padding: 100px 50px 0 50px;
  }
}
@media screen and (max-width: 991.99px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #252b42;
    z-index: 999;
    padding: 10px;
  }
  .navbarCustom .navbar-nav .nav-item {
    padding: 0;
  }
  .navbarCustom .navbar-nav .nav-item .nav-link {
    text-align: center;
    padding: 20px 0;
  }
  .headerSocial {
    display: block;
    text-align: center;
    padding: 20px 0;
  }
}
@media screen and (max-width: 575.99px) {
  .navbarCustom .navbar-brand .logo {
    height: 40px;
  }
  .faqSection {
    padding: 200px 0;
  }
}
@media (min-width: 280px) {
  .navbarCustom .navbar-nav .nav-item .nav-link {
    font-size: calc(1.25rem + (1vw - 2.8px) * 0.3659);
    min-height: 0vw;
  }
  .font30 {
    font-size: calc(1.25rem + (1vw - 2.8px) * 0.6098);
    min-height: 0vw;
  }
  h1,
  .font38 {
    font-size: calc(1.875rem + (1vw - 2.8px) * 0.6098);
    min-height: 0vw;
  }
  .font26,
  .font26White {
    font-size: calc(1.125rem + (1vw - 2.8px) * 0.4878);
    min-height: 0vw;
  }
  .font30Gray,
  .footer .footerLine {
    font-size: calc(1.25rem + (1vw - 2.8px) * 0.6098);
    min-height: 0vw;
  }
} /*# sourceMappingURL=media.css.map */
