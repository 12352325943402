@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
body {
  font-family: "Comic Neue", cursive;
  font-optical-sizing: auto;
  font-size: 15px;
  overflow-x: hidden;
}

a {
  font-size: 15px;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.navbarCustom {
  background-color: transparent;
}
.navbarCustom .navbar-brand .logo {
  height: 50px;
}
.navbarCustom .navbar-nav .nav-item {
  padding: 10px 20px;
}
.navbarCustom .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
}
.navbarCustom .navbar-nav .nav-item .nav-link:hover {
  color: #d5803c;
}
.navbarCustom .socialIcon {
  width: 30px;
}

#navbar.scrolled {
  background-color: #252B42;
}

.mainBanner {
  background-image: url("../public/images/bannerBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 100px;
}
.mainBanner .byuBox {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 100px;
}

.heightFull {
  min-height: calc(100vh - 100px);
}

.colorTheme {
  color: rgb(45, 64, 89);
}

.font30 {
  font-size: 30px;
}

.fontBold {
  font-weight: 700;
}

h1 {
  font-size: 40px;
  color: #252B42;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  color: rgb(45, 64, 89);
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: solid 2px #E74040;
  display: inline-block;
}

.font38 {
  font-size: 38px;
  color: #252B42;
  font-weight: 700;
}

.font30Gray {
  font-size: 30px;
  font-weight: 700;
  color: #737373;
}

.font30 {
  font-size: 30px;
  color: #252B42;
  font-weight: 700;
}

.font26 {
  font-size: 26px;
  color: rgb(45, 64, 89);
}

.font26White {
  font-size: 26px;
  color: #fff;
  font-weight: 400;
}

.bodrBottom {
  border-bottom: solid 1px rgb(45, 64, 89);
}

.inputCustom {
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.btnPrimary {
  background-color: rgb(45, 64, 89);
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border: 0;
}
.btnPrimary:hover {
  background-color: #d5803c;
  color: #fff;
}

.textWrap {
  word-wrap: break-word;
}

.aboutusSection .aboutImg {
  margin-top: -150px;
  margin-left: -15px;
}
.aboutusSection .aboutSpace {
  padding: 200px 200px 0 100px;
}

.tokenomicsSection {
  background-image: url("../public/images/tokenomicsBG.png");
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.tokenomicsSection .box {
  background-color: #fff;
  box-shadow: 0px 6px 20px 0px rgba(43, 43, 43, 0.1764705882);
  border-radius: 20px;
}
.tokenomicsSection .tokenomicsImg {
  display: flex;
  align-items: end;
}

.tokenDistribution {
  padding-bottom: 100px;
}
.tokenDistribution .tokenBox {
  width: 26px;
  height: 26px;
  border-radius: 5px;
}
.tokenDistribution .liquidity {
  background-color: #739B9C;
}
.tokenDistribution .presale {
  background-color: #E7A86D;
}
.tokenDistribution .airdrop1 {
  background-color: #1D1C1F;
}
.tokenDistribution .airdrop2 {
  background-color: #1A364C;
}
.tokenDistribution .taxes {
  background-color: #C2864D;
}
.tokenDistribution .marketing {
  background-color: #352129;
}
.tokenDistribution .team {
  background-color: #764C35;
}
.tokenDistribution .community {
  background-color: #A09F9F;
}

.ourPartners {
  padding: 100px 0;
}
.ourPartners .logoBox {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 6px 20px 0px rgba(43, 43, 43, 0.1764705882);
}

.howToBuySection {
  padding-bottom: 100px;
}

.faqSection {
  background-image: url("../public/images/bgFAQ.png");
  background-size: cover;
  padding: 300px 0;
}
.faqSection .faqBox {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 6px 20px 0px rgba(43, 43, 43, 0.1764705882);
}

.footer {
  background-color: rgb(45, 64, 89);
  padding: 50px 0;
}
.footer .logoFooter {
  width: 200px;
}
.footer .footerLine {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}/*# sourceMappingURL=custom.css.map */